import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from './Layout'
import NotFound from './components/Pages/NotFound'
import DocumentRequestMenu from './components/Pages/DocumentRequestMenu'
import PayrollQueryMenu from './components/Pages/PayrollQueryMenu'
import { deepmerge } from '@mui/utils'
import {
    Experimental_CssVarsProvider as CssVarsProvider,
    experimental_extendTheme as extendMuiTheme,
} from '@mui/material/styles'
import { extendTheme as extendJoyTheme } from '@mui/joy/styles'
import Home from './components/Pages/Home'
import AddTimesheet from './components/Pages/Timesheet/Set/AddTimesheet'
import PolicyPage from './components/Policies/PolicyPage'
import ScrollToTop from './components/Buttons/ScrollToTop'
import timesheetInitialValues from "./components/Pages/Timesheet/FormModel/timesheetInitialValues";
import payslipInitialValues from "./components/Pages/Payslip/FormModel/payslipInitialValues";
import payrollInitialValues from "./components/Pages/Payroll/FormModel/payrollInitialValues";
import attachmentPageData from "./data/attachmentPageData";
import queryAttachInitialValues from "./components/Pages/QueryAttach/FormModel/QueryAttachInitialValues";
import generalQuerySchema from "./components/Pages/GeneralQuery/FormModel/generalQuerySchema";
import payrollSchema from "./components/Pages/Payroll/FormModel/payrollSchema";
import myEpayInitialValues from "./components/Pages/MyEpay/myEpayInitialValues";
import invoiceSchema from "./components/Pages/Invoice/invoiceSchema";
import invoiceInitialValues from "./components/Pages/Invoice/invoiceInitialValues";
import AnnouncementBanner from "./components/Banner/AnnouncementBanner";

const joyTheme = extendJoyTheme({
    cssVarPrefix: 'mui',
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    solidColor: 'var(--mui-palette-primary-contrastText)',
                    solidBg: 'var(--mui-palette-primary-main)',
                    solidHoverBg: 'var(--mui-palette-primary-dark)',
                    plainColor: 'var(--mui-palette-primary-main)',
                    plainHoverBg:
                        'rgba(var(--mui-palette-primary-mainChannel) / var(--mui-palette-action-hoverOpacity))',
                    plainActiveBg: 'rgba(var(--mui-palette-primary-mainChannel) / 0.3)',
                    outlinedBorder: 'rgba(var(--mui-palette-primary-mainChannel) / 0.5)',
                    outlinedColor: 'var(--mui-palette-primary-main)',
                    outlinedHoverBg:
                        'rgba(var(--mui-palette-primary-mainChannel) / var(--mui-palette-action-hoverOpacity))',
                    outlinedHoverBorder: 'var(--mui-palette-primary-main)',
                    outlinedActiveBg: 'rgba(var(--mui-palette-primary-mainChannel) / 0.3)',
                },
                neutral: {},
                divider: 'var(--mui-palette-divider)',
                text: {
                    tertiary: 'rgba(0 0 0 / 0.56)',
                },
            },
        },
    },
    fontFamily: {
        display: '"Arial",sans-serif',
        body: '"Arial",sans-serif',
    },
})
const muiTheme = extendMuiTheme()
const theme = deepmerge(joyTheme, muiTheme)

export default function App() {
    const attachmentPage = attachmentPageData
    return (
        <CssVarsProvider theme={theme}>
            <BrowserRouter>
                <ScrollToTop />
                <AnnouncementBanner />
                <Routes>
                    <Route path='/' element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path='document-request' element={<DocumentRequestMenu />} />
                        <Route path='payroll' element={<PayrollQueryMenu />} />
                        <Route path='*' element={<NotFound />} />
                        <Route path='timesheet' element={<AddTimesheet
                            title={'Submit Hours & Timesheet'}
                            subtitle={`timesheet`}
                            timesheet={true} initialValues={timesheetInitialValues}
                            validationSchema={generalQuerySchema}
                        />} />
                        <Route
                            path='document-request/payslip' element={<AddTimesheet
                            title={'Request a Copy of Payslip'}
                            subtitle={`copy of payslip request`}
                            payslip={true} initialValues={payslipInitialValues}
                            validationSchema={generalQuerySchema}
                        />} />
                        <Route
                            path='document-request/p60' element={<AddTimesheet
                            title={'Request a Copy of P60'}
                            subtitle={`copy of P60 request`}
                            p60={true} initialValues={payslipInitialValues}
                            validationSchema={generalQuerySchema}
                        />} />
                        <Route
                            path='document-request/p45' element={<AddTimesheet
                            title={'Request a Copy of P45'}
                            subtitle={`copy of P45 request`}
                            p45={true} initialValues={payslipInitialValues}
                            validationSchema={generalQuerySchema}
                        />} />
                        <Route
                            path='payroll/query' element={<AddTimesheet
                            title={'Payroll Query'}
                            subtitle={`raise payroll queries`}
                            payroll={true}
                            initialValues={payrollInitialValues}
                            validationSchema={payrollSchema}
                        />} />
                        <Route
                            path='myepay' element={<AddTimesheet
                            title={'MyePay Registration Request'}
                            subtitle={`MyePay registration request`}
                            myepay={true}
                            initialValues={myEpayInitialValues}
                            validationSchema={generalQuerySchema}
                        />} />

                        <Route
                            path='invoice' element={<AddTimesheet
                            title={'Invoice and Managed Account Query'}
                            subtitle={`raise an invoice and managed account query`}
                            invoice={true}
                            initialValues={invoiceInitialValues}
                            validationSchema={invoiceSchema}
                        />} />

                        {attachmentPage.map((page) => (
                            <Route
                                key={page}
                                path={page.path}
                                element={
                                    <AddTimesheet
                                        title={page.title}
                                        subtitle={page.subtitle}
                                        attachment={true}
                                        initialValues={queryAttachInitialValues}
                                        complaints={page.complaints}
                                    />
                                }
                            />
                        ))}

                        {/* Policies pages */}
                        <Route
                            path='/policies/privacy-policy'
                            element={<PolicyPage name={'privacy-policy.md'} />}
                        />

                        <Route
                            path='/policies/complaints-and-compliments'
                            element={<PolicyPage name={'complaints-and-compliments.md'} />}
                        />

                        <Route
                            path='/policies/complaints-and-compliments-procedure-d'
                            element={
                                <PolicyPage name={'complaints-and-compliments-procedure-d.md'} />
                            }
                        />

                        <Route
                            path='/policies/acceptable-use-policy'
                            element={<PolicyPage name={'acceptable-use-policy.md'} />}
                        />

                        <Route
                            path='/policies/terms-and-conditions'
                            element={<PolicyPage name={'terms-and-conditions.md'} />}
                        />

                        <Route
                            path='/policies/cookies-policy'
                            element={<PolicyPage name={'cookies-policy.md'} />}
                        />

                        <Route
                            path='/policies/modern-slavery'
                            element={<PolicyPage name={'modern-slavery.md'} />}
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </CssVarsProvider>
    )
}
