import React from 'react';
import { Typography } from '@mui/joy';

export default function AnnouncementBanner() {
    return (
        <div style={{
            backgroundColor: 'darkred',
            padding: 5,
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
            position: 'sticky',
            top: 0,
            zIndex: 1000
        }}>
            <div style={{
                backgroundColor: 'teal',
                borderRadius: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '8px',
                maxWidth: '95%',
                margin: '0 auto',
            }}>
                <span style={{
                    backgroundColor: 'yellow',
                    borderRadius: '50%',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '30px',
                    height: '30px',
                    marginRight: '8px',
                    fontSize: '20px'
                }}> &#128736; </span>
                <Typography sx={{
                    fontWeight: 'bold',
                    color: 'white',
                    fontSize: { xs: '0.875rem', sm: '1rem', md: '18px' },
                    textAlign: 'center',
                    width: '100%',
                    paddingRight: 1
                }}>
                    The ILS Contact Form will be down for maintenance on Monday 7th October from 5pm, while we prepare to launch the new form.
                </Typography>
            </div>
        </div>
    );
}
